import { Link } from "gatsby"
import React from "react"

export const ServiceSectionEn = () => {
  return (
    <section>
      <div className="free-services">
        <p className="title">All the services mentioned above are free.</p>
        <p className="text">
          Join us and let our experts assist you in finding your ideal job.
        </p>
        <Link to="/en/job-form" className="free-consultation-btn ">
          <button>Free Consultation</button>
        </Link>

        <ul className="mt-40">
          <li>
            Please note that a registration interview is required to receive
            specific job introductions.
          </li>
          <li>Registration does not guarantee job placement or employment.</li>
          <li>
            Depending on your preferences and experience, it may be difficult to
            find suitable job opportunities. We appreciate your understanding.
          </li>
          <li>All services provided by our company are free of charge.</li>
        </ul>
      </div>
    </section>
  )
}
